import React, {useEffect, useState, useContext} from 'react';
import { Link } from "gatsby"
import useTranslations from "../../utils/useTranslations"
import { FaApple } from 'react-icons/fa';
import Button from "../../components/button"
import fetchCherryData from "../../utils/cherry"
import Logo from "../../components/logo"
import btwebScreenshot from "../../images/downloads/btweb-windows-screen.png"
import btwebScreenshot2x from "../../images/downloads/btweb-windows-screen@2x.png"
import btclassicScreenshot from "../../images/downloads/btclassic-mac-screen.jpg"
import btclassicScreenshot2x from "../../images/downloads/btclassic-mac-screen@2x.jpg"
import btclassicScreenshot3x from "../../images/downloads/btclassic-mac-screen@3x.jpg"

import { DownloadModals } from "./windows"
import warning from "../../images/icons/warning.svg"
import { CompatibilityContext } from "../../components/compatibilityContext";


const langPackOsx ="https://legacy.utorrent.com/scripts/dl.php?track=stable&build=29561&client=btmac"

const DownloadsMac = ({ pageContext: {text, locale} }) => {
const t = useTranslations(text)

	const [dataClassic, setDataClassic] = useState({});

	useEffect(() => {
			fetchCherryData("btmac", "osx", locale)
			.then((res) => {
				setDataClassic(res)
			})


		},[locale]
	)

	const [modal, setModal] = useState(false);
	const [modalType, setModalType] = useState("");

	const toggle = (modalType) => {
		setModalType(modalType)
		setModal(!modal)
	}

	const compatibilityHandler = useContext(CompatibilityContext)
	function handleClick(e, action, product, link){
		compatibilityHandler(e, action, product, ["Osx"], link)
	}

	const webLink = "/products/mac/bittorrent-web-compare/"
	const classicLink = "/downloads/complete/classic"

	const langPackSize = "(597.86 KB)"
	const langPackDate = "October 19, 2019"
	let langPackD = new Date(langPackDate)
	langPackD = langPackD.toLocaleDateString(locale.replace('_','-'))

  return (
		<div className="container my-5 pt-5">
			<div className="row">
				<div className="col-12 text-center text-md-left">
					<h1><FaApple className="mb-2" />  <span className="ml-2">{t("BitTorrent Downloads for Mac")}</span></h1>
					<div className="text-size-24 mt-2 mb-4 text-muted">{t("The most trusted torrent clients for Mac in the world.")}</div>
				</div>
			</div>
			<div className="row mt-5">
				<div className="col-md-7 order-2 order-md-1 text-center text-md-left">
					<div className="mb-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
						<div className="d-flex flex-column flex-md-row justify-content-center align-items-center text-nowrap">
		              		<Logo color="black" tag="h2" productName="Web" className="text-center text-md-left mb-0" fsMax={21.5} fixedParentDistance={3}/>
		              	</div>
					</div>
					<p className="text-size-20 mb-2 text-muted">{t("The easy-to-use online torrent downloader and player for Mac.")}</p>
					<p className=""> * Having trouble installing on Mac? Find the solution <a href="/en/support/solutions/articles/29000034450--will-damage-your-computer-you-should-move-it-to-the-trash-" target="_blank" rel="noopener noreferrer" className="text-primary">here</a>.</p>
					<Button href={webLink} id="mac-downloads-webfree" className="btn-primary button-filled text-spacing-1">{t("Free Download")}</Button>
				</div>
				<div className="col-md-5 order-1 order-md-2 mb-4 mb-md-0 text-center text-md-right">
					<img src={btwebScreenshot} srcSet={`${btwebScreenshot2x} 2x`} alt="BitTorrent Web Screenshot" className="img-fluid w-100"></img>
	                <div style={{fontSize: '10px'}} className="mt-2 mb-4 text-center"><a id="downloads-win-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>
				</div>
				<div className="col-12 order-3 mt-5">
					<hr style={{ margin: `10px 0 10px 0` }} />
				</div>
			</div>
			<div className="row mt-5">
				<div className="col-md-7 order-2 order-md-1 text-center text-md-left">
					<div className="mb-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
		              	<div className="d-flex flex-column flex-lg-row justify-content-center align-items-center text-nowrap">
		              		<Logo color="black" tag="h2" productName="Classic" className="text-center text-md-left mb-0" fsMax={22} fixedParentDistance={3}/>
		              		<div className="ml-0 ml-md-5 mb-3 mb-md-0">(Stable {dataClassic.version})</div>
		              	</div>
					</div>
					<p className="text-size-20 mb-2 text-muted">{t("The original desktop torrent <span className='d-block d-sm-inline-block'>client for Mac.</span>")}</p>
					<p>{t("For Mac")} ({dataClassic.fileSize}) | {t("English (US) –")} {dataClassic.lastModified} </p>
					<Button onClick={(e) => {handleClick(e, "downloading", "Classic", classicLink)}} href={classicLink} id="mac-downloads-classicfree" className="btn-primary button-filled text-spacing-1">{t("Free Download")}</Button>
					<div className="d-block d-xl-inline mt-3 mt-xl-0">
						<img src={warning} alt="Warning Icon" style={{ height: `20px` }} className="warning-icon mr-1 d-inline pb-1"/>
                    	<p className="d-inline text-left small"> {t(`Not compatible with MAC OS Catalina Version 10.15 and above`)}</p>
                    </div>
				</div>
				<div className="col-md-5 order-1 order-md-2 mb-4 mb-md-0 text-center text-md-right">
					<img src={btclassicScreenshot} srcSet={`${btclassicScreenshot2x} 2x, ${btclassicScreenshot3x} 3x`} alt="BitTorrent Classic Screenshot" className="img-fluid w-100"></img>
				</div>
				<div className="col-12 order-3 mt-5">
					<hr style={{ margin: `10px 0 10px 0` }} />
				</div>
			</div>
			<div className="row mt-3">
				<div className="col-12 text-center text-md-left mb-5">
					<h2 className="text-size-16 mb-2">
						<Link to="/blog/releases/mac/" id="downloads-mac-releasenotes" className="text-decoration-none text-body">
                          <u>{t("Release Notes")}</u>
                        </Link>
					</h2>
					<a id="downloads-mac-langpack" className="text-dark" href={langPackOsx}>{t(`Download language pack`)} {langPackSize} - {langPackD}</a> | <a id="downloads-mac-availlangs" className="underline pointer"onClick={()=>{toggle("Languages")}}>{t(`Available Languages`)}</a> | <a id="downloads-mac-thx-trans" className="underline pointer"onClick={()=>{toggle("Translators")}} >{t(`Thanks to our Translators`)}</a>
					<p className='d-block'><i>{t(`To install, place BitTorrent.lng in /Applications/BitTorrent.app/Contents/MacOS/`)}</i></p>
				</div>
			</div>

			<DownloadModals
				isOpen={modal}
				toggle={toggle}
				type={modalType} 
			/>
		</div>
  )
}

export default DownloadsMac